import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dimmer, Form, Icon, Divider, Image, Loader, Item, Modal, Card, Header, Container } from 'semantic-ui-react';
import firebase from './firebase'
import appSlice, { getApp } from './store/slice/app.slice';
import { BRGY_CONFIG } from './utils/constants';
import axios from "axios";

const App = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true)
  const [load, setLoad] = useState(false)
  const [loadCounter, setLoadCounter] = useState(0)
  const [loadCounterDone, setLoadCounterDone] = useState(false)
  const [notFound, setNotFound] = useState(true)
  const [ip, setIP] = useState("")
  const [openResultPage, setOpenResultPage] = useState(false)
  const { 
    data
  } = useSelector(getApp)

  const {
    brgyName,
    db,
    googleFormLink,
    googleFormWidth,
    googleFormHeight,
    headerColor,
    headerLogo,
    mainCard,
    cardImages,
    saveNameIP,
    strictSearch
  } = BRGY_CONFIG['PLT']

  useEffect(()=>{
    if(loadCounter === 2){
      setLoadCounterDone(true)
    }
  },[loadCounter])

  const handleFirst = (e) => {
    const { value } = e.target
    dispatch(
      appSlice.actions.setData(
        {
          ...data, 
          first: value.toUpperCase()
        }
    ))
  }

  useEffect(() => {
    getData();
  }, []);


  const handleLast = (e) => {
    const { value } = e.target
    dispatch(
      appSlice.actions.setData(
        {
          ...data, 
          last: value.toUpperCase()
        }
    ))
  }

  const handleCheckIn = () => {
    setOpen(false)
    setLoad(true)
    populate()
  }

  const populate = async() => {
      const dbx = await firebase.firestore();

      let searchType = strictSearch ? '==' : '>='

      await dbx.collection(db)
      .where("LAST", "==", data.last)
      .where("FIRST", searchType, data.first)
      .limit(1)
      .get()
      .then((querySnapshot) => {
          let obj = {}

          querySnapshot.forEach((doc) => {
              obj = { ...doc.data() }
          });

            setTimeout(() => {
                setOpenResultPage(true)
                 setLoad(false)
                if(JSON.stringify(obj) === '{}'){
                  console.log('notfound', obj)
                  setNotFound(true)
                  dispatch(appSlice.actions.setData({
                    result: {
                        name: '' ,
                        precinct: '',
                        cluster: ''
                    },
                  }))
                }else{
                  console.log('found', obj)
                  setNotFound(false)
                  dispatch(appSlice.actions.setData({
                    result: {
                      name: `${obj.LAST}, ${obj.FIRST} ${obj.MIDDLE ? obj.MIDDLE : ''}`,
                      precinct: obj.PRECINCT,
                      cluster: obj.CLUSTER,
                      add: obj.ADDRESS ? obj.ADDRESS : '',
                      birthday: obj.BIRTHDAY ? obj.BIRTHDAY : ''
                    },
                  }))
                }
            }, 1000);
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });
  }

  const validate = () => {
    if(data.first && data.last){
      return (data.first.length === 0 || data.last.length === 0)
    }

    return false
  }

  const renderOptions = () => {
    return (
      <>
      <Dimmer active={load}>
        <Loader>Loading</Loader>
      </Dimmer>

      <Modal
        size={'tiny'}
        open={open}
      >
        <Modal.Header className="header-mdl">
          <label>{brgyName} Precinct Finder</label>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Input First Name</label>
              <input onChange={handleFirst} value={data.first} maxLength={20}/>
              <br/><br/>
              <label>Input Last Name</label>
              <input onChange={handleLast} value={data.last} maxLength={20}/>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <label style={{fontSize: '9px', paddingLeft: '1rem'}}>This Precinct Finder V3 is applicable to {brgyName} Voters ONLY</label>
          <Button color={'blue'} onClick={handleCheckIn} disabled={validate()}>
            Search
          </Button>
        </Modal.Actions>
      </Modal>
      </>
    )
  }

  const handleOnload = () => {
    setLoadCounter(loadCounter+1)
  }

  const renderNav = () => {
    return (
          <div className="navbar" style={{backgroundColor: headerColor}}>
          {
            headerLogo && <Image src={headerLogo} size='small' className='navbar-img'/>   
          }
          </div>
    )
  }

  const renderRes = () => {
    return (
      <>
        {
          !loadCounterDone ? (
            !notFound ? renderIframe() : renderNotFound() 
          ) : (
            renderCardResult()
          )
        }
      </>
    )
  }

  const renderNotFound = () => {
    return (
        <div style ={{overflowY: "auto", textAlign: 'center'}}>
          {renderNav()}
          <Divider/>
          <Header as='h2'  style={{ textAlign: 'left', marginLeft: '2rem'}}>
            <Icon name='search' />
            <Header.Content>
              DATA NOT AVAILABLE. <br/>Please provide your correct name.
              <Header.Subheader><a href={''}>CLICK HERE</a> to search again...</Header.Subheader>
            </Header.Content>
          </Header>
          <Divider/>
        </div>
    )
  }

  const renderCardResult = () => {
    return (
      <div style={{overflowY: "auto"}}>
        {renderNav()}
        <Container style={{ marginTop: '3rem', textAlign: 'center'}}>
          <Item.Group relaxed>
            <Item>
              <Item.Image size='large' src={mainCard}/>
              <Divider/>
              <Item.Content verticalAlign='bottom'>
                <Item.Description>
                    <Header as='h2'>
                    {data.result.name}
                    <Header.Subheader>NAME</Header.Subheader>
                  </Header>
                  <Header as='h2' >
                    {data.result.cluster}
                    <Header.Subheader>CLUSTER PRECINCT</Header.Subheader>
                  </Header>
                  <Header as='h2' >
                    {data.result.precinct}
                    <Header.Subheader>PRECINCT NUMBER</Header.Subheader>
                  </Header>
                  {
                    data.result.add && (
                    <Header as='h2' >
                      {data.result.add}
                      <Header.Subheader>ADDRESS</Header.Subheader>
                    </Header>
                    )
                  }
                  {
                    data.result.birthday && (
                    <Header as='h2' >
                      {data.result.birthday}
                      <Header.Subheader>BIRTHDAY</Header.Subheader>
                    </Header>
                    )
                  }
                </Item.Description>
              </Item.Content> 
            </Item>
          </Item.Group>

          <Header as='h2'  style={{ textAlign: 'left'}}>
          <Divider/>
            <Icon name='photo' />
            <Header.Content>
              You may take a screenshot of your details.
              <Header.Subheader><a href={''}>CLICK HERE</a> to search again...</Header.Subheader>
            </Header.Content>
          </Header>
          <Divider/>

          {
            cardImages.length <= 1 ? (
              <Image src={cardImages[0]} size={'large'}/>
            ) : (
              <>
              <Card.Group itemsPerRow={3} style={{margin: '0rem'}} >
                <Card raised image={cardImages[0]} />
                <Card raised image={cardImages[1]} />
                <Card raised image={cardImages[2]} />
                <Card raised image={cardImages[3]} />
                <Card raised image={cardImages[4]} />
                <Card raised image={cardImages[5]} />
                <Card raised image={cardImages[6]} />
              </Card.Group>
              <Divider/>
              </>
            )
          }

           
        </Container>
      </div>
    )
  }

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log('ip',res.data);
    setIP(res.data.ip);
  };

  const renderIframe = () => {
    if(googleFormLink === ''){
      return (
        !notFound ? renderCardResult() : renderNotFound() 
      )
    }else{

      let dataresname=''

      if(saveNameIP){
        let concatenated = `${ip}-${data.result.name}`
        dataresname = `&usp=pp_url&entry.${saveNameIP}=${btoa(concatenated)}`
      }else{
        dataresname=''
      }

      return (
        <iframe
              title={'electoralSurvey'}
              src={`${googleFormLink}${dataresname}`}
              width={googleFormWidth}
              height={googleFormHeight}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              onLoad={handleOnload}>Loading…
        </iframe>
      )
    }
  }

  return (
    <div className="App">
      {
        openResultPage && renderRes()
      }
      {
        !openResultPage && renderOptions()
      }
    </div>
  );
}

export default App;
